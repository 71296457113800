@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body {
  margin: 0;
  font-family:"Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600
}



@tailwind base;

@tailwind components;

@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.church-website{
  background-color: whitesmoke;
  direction: rtl;
  min-height: 100vh;
  padding: 0px 0px;
}

.father-dashboard-card {
  @apply flex flex-col items-center justify-center bg-white shadow-lg rounded-xl p-6 transition-all duration-300 hover:bg-lime-500 hover:text-white transform hover:scale-105;
}

@media print {

  button,
  input,
  .print-hidden {
    display: none;
  }

  table {
    width: 100%;
  }

  .print-visible {
    display: block !important;
  }
}